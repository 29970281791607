@import "colors";
@import "misc";

.modal{
    position: fixed;
    top:0;
    inset-inline-start:0;
    width: 100%;
    height: 100%;
    background-color: $secondaryColor_90;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 5;

    .content-wrapper{
        position: relative;
        display: flex;
        flex-direction: row;
        height: auto;
        min-height: 0;
        width: 80%;
        max-width: 1200px;
        background-color: white;
        background-image: url("/assets/img/pop-up-background-emm.t7619078003e0a944c13a415747245ca8.jpg");
        background-size: cover;
        background-position-x: 50%;
        background-position-y: 0%;
        max-height: 80vh;
        height: auto;
        overflow: auto;
        @media (max-width: $mobile) {
            width: auto;
            margin:10px;
        }

        .close{
            position: absolute;
            top:0;
            inset-inline-end: 0;
            color: #000;
            line-height: 1;
            padding: 1rem;
            cursor: pointer;
            font-weight: 800;
        }

        .text{
            color: #fff;
            padding:5rem;
            @media (max-width: $tablet) {
                display: none;
            }
        }
        .form{
            background-color: #fff;
            padding: 5rem 3rem;
            height: 100%;
            animation: slide 2s forwards;
            animation-delay: 1s;
            width: 100%;
            // margin-inline-start: 1000px;
            // opacity: 0;
            .container{
                padding-top:0;
            }

            @media (max-width: $mobile) {
                padding:0.5rem;
                padding-top: 2rem;
            }
        }
        @keyframes slide {
            100% { 
                // margin-inline-start: 0;
                // opacity: 1;
            }
        }
        h3{
            color: #fff;
            font-size: 25,6px;
            font-weight: 400;
            margin-top:0;
        }
        h4{
            font-size: 23,2px;
            font-weight: 400;
        }
    }
}